// assets
import { IconUserPlus, IconUser, IconSchool, IconBuilding } from '@tabler/icons';

// constant
const icons = {
    IconUserPlus,
    IconUser,
    IconSchool,
    IconBuilding
};

// ==============================|| AMBASSADOR MENU ITEMS ||============================== //

const ambassadorPages = {
    id: 'ambassadorPages',
    title: 'Ambassador Pages',
    caption: 'Ambassador Pages Caption',
    type: 'group',
    children: [
        {
            id: 'profile',
            title: 'Profile',
            type: 'item',
            url: '/profile',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'invite',
            title: 'Invite',
            type: 'item',
            url: '/invite',
            icon: icons.IconUserPlus,
            breadcrumbs: false
        },
        {
            id: 'students',
            title: 'Students',
            type: 'item',
            url: '/students',
            icon: icons.IconSchool,
            breadcrumbs: false
        },
        {
            id: 'employers',
            title: 'Employers',
            type: 'item',
            url: '/employers',
            icon: icons.IconBuilding,
            breadcrumbs: false
        }
    ]
};

export default ambassadorPages;
