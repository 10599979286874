import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

const LinkedInPopup = Loadable(lazy(() => import('views/pages/authentication/linkedIn-popup')));
const NotFound = Loadable(lazy(() => import('views/error/404')));
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword')));
const VerifyEmail = Loadable(lazy(() => import('views/pages/authentication/authentication3/VerifyEmail')));
const NewPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/NewPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLogin3 />
        },
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/register',
            element: <AuthRegister3 />
        },
        {
            path: '/register/:ambassadorID',
            element: <AuthRegister3 />
        },
        {
            path: '/linkedIn-popup',
            element: <LinkedInPopup />
        },
        {
            path: '/reset-password',
            element: <ResetPassword />
        },
        {
            path: '/new-password/:userID',
            element: <NewPassword />
        },
        {
            path: '/email-verification/:userID',
            element: <VerifyEmail />
        },
        {
            path: '*',
            element: <NotFound />
        }
    ]
};

export default AuthenticationRoutes;
