import { useRoutes } from 'react-router-dom';

import config from 'config';
import userTypes from 'constants/userTypes';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import EmployerRoutes from './EmployerRoutes';
import StudentRoutes from './StudentRoutes';
import AdminRoutes from './AdminRoutes';
import AmbassadorRoutes from './AmbassadorRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes({ user, userType }) {
    let routes = [AuthenticationRoutes];

    if (user) {
        if (userType === userTypes.Admin) {
            routes = [AdminRoutes, AuthenticationRoutes];
        } else if (userType === userTypes.Ambassador) {
            routes = [AmbassadorRoutes, AuthenticationRoutes];
        } else if (userType === userTypes.Employer) {
            routes = [EmployerRoutes, AuthenticationRoutes];
        } else if (userType === userTypes.Student) {
            routes = [StudentRoutes, AuthenticationRoutes];
        } else {
            routes = [AuthenticationRoutes];
        }
    }

    return useRoutes(routes, config.basename);
}
