import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const EmployerRequests = Loadable(lazy(() => import('views/employer-requests')));
const Jobs = Loadable(lazy(() => import('views/jobs')));
const Profile = Loadable(lazy(() => import('views/profile')));
const Connections = Loadable(lazy(() => import('views/connections')));
const Chat = Loadable(lazy(() => import('views/chat')));
const MyAmbassador = Loadable(lazy(() => import('views/my-ambassador')));

// ==============================|| STUDENT ROUTING ||============================== //

const StudentRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Profile />
        },
        {
            path: '/jobs',
            element: <Jobs />
        },
        {
            path: '/employer-requests',
            element: <EmployerRequests />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/connections/:profileID',
            element: <Profile />
        },
        {
            path: '/connections',
            element: <Connections />
        },
        {
            path: '/chat',
            element: <Chat />
        },
        {
            path: '/chat/:chatID',
            element: <Chat />
        },
        {
            path: '/my-ambassador',
            element: <MyAmbassador />
        }
    ]
};

export default StudentRoutes;
