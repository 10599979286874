// assets
import { IconUser, IconBriefcase, IconAffiliate, IconMailOpened, IconMessage, IconFlag } from '@tabler/icons';

// constant
const icons = {
    IconUser,
    IconBriefcase,
    IconMailOpened,
    IconAffiliate,
    IconMessage,
    IconFlag
};

// ==============================|| STUDENT MENU ITEMS ||============================== //

const studentPages = {
    id: 'studentPages',
    title: 'Student Pages',
    caption: 'Student Pages Caption',
    type: 'group',
    children: [
        {
            id: 'profile',
            title: 'Profile',
            type: 'item',
            url: '/profile',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'myAmbassador',
            title: 'My Ambassador',
            type: 'item',
            url: '/my-ambassador',
            icon: icons.IconFlag,
            breadcrumbs: false
        },
        {
            id: 'jobs',
            title: 'Jobs',
            type: 'item',
            url: '/jobs',
            icon: icons.IconBriefcase,
            breadcrumbs: false
        },
        {
            id: 'employerRequests',
            title: 'Employer Requests',
            type: 'item',
            url: '/employer-requests',
            icon: icons.IconMailOpened,
            breadcrumbs: false
        },
        {
            id: 'connections',
            title: 'Connections',
            type: 'item',
            url: '/connections',
            icon: icons.IconAffiliate,
            breadcrumbs: false
        },
        {
            id: 'chat',
            title: 'Chat',
            type: 'item',
            url: '/chat',
            icon: icons.IconMessage,
            breadcrumbs: false
        }
    ]
};

export default studentPages;
