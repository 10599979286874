import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const AccountManagement = Loadable(lazy(() => import('views/account-management')));
const CreateAccount = Loadable(lazy(() => import('views/account-management/create-account')));
const Profile = Loadable(lazy(() => import('views/profile')));
const AdminStudents = Loadable(lazy(() => import('views/admin-students')));
const AdminEmployers = Loadable(lazy(() => import('views/admin-employers')));
const AdminAmbassadors = Loadable(lazy(() => import('views/admin-ambassadors')));
const AdminEmployerJobs = Loadable(lazy(() => import('views/admin-employer-jobs')));

// ==============================|| ADMIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <AccountManagement />
        },
        {
            path: '/account-management',
            element: <AccountManagement />
        },
        {
            path: '/create-account',
            element: <CreateAccount />
        },
        {
            path: '/students/:profileID/:ambassadorEditing',
            element: <Profile />
        },
        {
            path: '/employers/:profileID/:ambassadorEditing',
            element: <Profile />
        },
        {
            path: '/ambassadors/:profileID/:adminEditing',
            element: <Profile />
        },
        {
            path: '/students',
            element: <AdminStudents />
        },
        {
            path: '/employers',
            element: <AdminEmployers />
        },
        {
            path: '/employers/:profileID/jobs',
            element: <AdminEmployerJobs />
        },
        {
            path: '/ambassadors',
            element: <AdminAmbassadors />
        }
    ]
};

export default AdminRoutes;
