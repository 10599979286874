// assets
import { IconUsers, IconSchool, IconBuilding, IconFlag } from '@tabler/icons';

// constant
const icons = {
    IconUsers,
    IconSchool,
    IconBuilding,
    IconFlag
};

// ==============================|| ADMIN MENU ITEMS ||============================== //

const adminPages = {
    id: 'adminPages',
    title: 'Admin Pages',
    caption: 'Admin Pages Caption',
    type: 'group',
    children: [
        {
            id: 'accountManagement',
            title: 'Account Management',
            type: 'item',
            url: '/account-management',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'ambassadors',
            title: 'Ambassadors',
            type: 'item',
            url: '/ambassadors',
            icon: icons.IconFlag,
            breadcrumbs: false
        },
        {
            id: 'employers',
            title: 'Employers',
            type: 'item',
            url: '/employers',
            icon: icons.IconBuilding,
            breadcrumbs: false
        },
        {
            id: 'students',
            title: 'Students',
            type: 'item',
            url: '/students',
            icon: icons.IconSchool,
            breadcrumbs: false
        }
    ]
};

export default adminPages;
