import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Feed = Loadable(lazy(() => import('views/feed')));
const EmployerOnboarding = Loadable(lazy(() => import('views/employer-onboarding')));
const JobListing = Loadable(lazy(() => import('views/job-listing')));
const Profile = Loadable(lazy(() => import('views/profile')));
const Connections = Loadable(lazy(() => import('views/connections')));
const Chat = Loadable(lazy(() => import('views/chat')));
const Wallet = Loadable(lazy(() => import('views/wallet')));
const TopUp = Loadable(lazy(() => import('views/wallet/top-up')));
const AddCard = Loadable(lazy(() => import('views/wallet/add-card')));

// ==============================|| EMPLOYER ROUTING ||============================== //

const EmployerRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Feed />
        },
        {
            path: '/feed',
            element: <Feed />
        },
        {
            path: '/employer-onboarding',
            element: <EmployerOnboarding />
        },
        {
            path: '/job-listing',
            element: <JobListing />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/connections/:profileID',
            element: <Profile />
        },
        {
            path: '/connections',
            element: <Connections />
        },
        {
            path: '/chat',
            element: <Chat />
        },
        {
            path: '/chat/:chatID',
            element: <Chat />
        },
        {
            path: '/wallet',
            element: <Wallet />
        },
        {
            path: '/wallet/top-up',
            element: <TopUp />
        },
        {
            path: '/wallet/add-card',
            element: <AddCard />
        }
    ]
};

export default EmployerRoutes;
