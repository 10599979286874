import adminPages from './adminPages';
import employerPages from './employerPages';
import studentPages from './studentPages';
import ambassadorPages from './ambassadorPages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [],
    adminItems: [adminPages],
    ambassadorItems: [ambassadorPages],
    employerItems: [employerPages],
    studentItems: [studentPages]
};

export default menuItems;
