import { useState, useRef, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery,
    Link,
    Badge,
    Snackbar
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';

// assets
import { IconBell } from '@tabler/icons';

// firebase
import { db } from 'firebase';
import { collection, query, where, orderBy, limit, getDocs, startAfter, updateDoc, doc, arrayUnion, onSnapshot } from 'firebase/firestore';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';
import userTypes from 'constants/userTypes';

const LIMIT = 10;

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const user = useSelector((state) => state.user);
    const userData = user.data;

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const [lastVisibleNotification, setLastVisibleNotification] = useState();
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
    const [state, setState] = useState({
        openSnackbar: false,
        vertical: 'top',
        horizontal: 'center',
        message: ''
    });

    const { vertical, horizontal, openSnackbar, message } = state;

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const fetchNotifications = async (userType, userID) => {
        setLoading(true);

        const notify = userType === userTypes.Admin ? userTypes.Admin : userID;
        const q = query(collection(db, 'notifications'), where('notify', '==', notify), orderBy('created', 'desc'), limit(LIMIT));
        const querySnapshot = await getDocs(q);

        const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        setLastVisibleNotification(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setNotifications(data);
        setLoading(false);
    };

    const fetchMoreNotifications = async (userType, userID) => {
        if (lastVisibleNotification) {
            const notify = userType === userTypes.Admin ? userTypes.Admin : userID;
            const q = query(
                collection(db, 'notifications'),
                where('notify', '==', notify),
                orderBy('created', 'desc'),
                limit(LIMIT),
                startAfter(lastVisibleNotification)
            );
            const querySnapshot = await getDocs(q);

            const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            setLastVisibleNotification(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setNotifications([...notifications, ...data]);
        }
    };

    useEffect(() => {
        if (open && userData.type && userData.id) {
            fetchNotifications(userData.type, userData.id);
        }
    }, [open, userData]);

    useEffect(() => {
        let unsubscribe;

        if (userData.type && userData.id) {
            const notify = userData.type === userTypes.Admin ? userTypes.Admin : userData.id;
            const q = query(collection(db, 'notifications'), where('notify', '==', notify));

            unsubscribe = onSnapshot(q, (querySnapshot) => {
                const unread = querySnapshot.docs.map((doc) => doc.data()).filter((n) => !n.readBy?.includes(userData.id)).length;
                setUnreadNotificationCount(unread);
            });
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [userData]);

    const markAllAsRead = async () => {
        const promises = [];
        const notificationsCopy = [...notifications];

        notificationsCopy.forEach((notification) => {
            // mark as read
            if (!notification.readBy?.includes(userData.id)) {
                const readBy = notification.readBy || [];
                notification.readBy = [...readBy, userData.id];
            }

            promises.push(updateDoc(doc(db, 'notifications', notification.id), { readBy: arrayUnion(userData.id) }));
        });

        setNotifications(notificationsCopy);
        await Promise.all([promises]);
    };

    const handleOpenSnackbar = (newState) => {
        setState({ openSnackbar: true, ...newState });
    };

    const handleCloseSnackbar = () => {
        setState({ ...state, openSnackbar: false, message: '' });
    };

    useEffect(() => {
        const welcomeMessage = localStorage.getItem('welcomeMessage');

        if (welcomeMessage) {
            handleOpenSnackbar({ vertical: 'top', horizontal: 'right', message: welcomeMessage });
            localStorage.removeItem('welcomeMessage');
        }
    }, []);

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.primary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.primary.dark,
                                color: theme.palette.secondary.light
                            },
                            width: '48px',
                            height: '48px'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <Badge badgeContent={unreadNotificationCount} color="primary">
                            <IconBell stroke={1.5} size="1.3rem" />
                        </Badge>
                    </Avatar>
                </ButtonBase>
            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                <Grid item>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography variant="subtitle1">All Notification</Typography>
                                                        <Chip
                                                            size="small"
                                                            label={unreadNotificationCount}
                                                            sx={{
                                                                color: theme.palette.background.default,
                                                                bgcolor: theme.palette.warning.dark
                                                            }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <Link component="button" variant="subtitle2" onClick={() => markAllAsRead()}>
                                                        Mark all as read
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PerfectScrollbar
                                                style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                            >
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item xs={12} p={0}>
                                                        <Divider sx={{ my: 0 }} />
                                                    </Grid>
                                                </Grid>
                                                <NotificationList
                                                    loading={loading}
                                                    notifications={notifications}
                                                    setNotifications={setNotifications}
                                                    setOpen={setOpen}
                                                    userData={userData}
                                                />
                                            </PerfectScrollbar>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                        <Button
                                            size="small"
                                            disableElevation
                                            onClick={() => fetchMoreNotifications(userData.type, userData.id)}
                                        >
                                            View More
                                        </Button>
                                    </CardActions>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                message={message}
                key={vertical + horizontal}
                action={
                    <>
                        <Button color="primary" size="small" variant="contained" onClick={handleCloseSnackbar}>
                            Ok
                        </Button>
                    </>
                }
                autoHideDuration={5000}
            />
        </>
    );
};

export default NotificationSection;
