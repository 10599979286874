// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// production configuration
const firebaseConfig = {
    apiKey: 'AIzaSyD5UHqUWk1MIy2nrAQi-t8VBUry-e5e3h4',
    // authDomain: 'campus-life-production.firebaseapp.com',
    authDomain: 'app.campuslife.com.au',
    projectId: 'campus-life-production',
    storageBucket: 'campus-life-production.appspot.com',
    messagingSenderId: '805778252953',
    appId: '1:805778252953:web:c724650e71b58350a9c408',
    measurementId: 'G-REH3FV1PJ8'
};

const projectID = firebaseConfig.projectId;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

// Google auth
const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.addScope('profile');
googleAuthProvider.addScope('email');

// Facebook auth
const facebookAuthProvider = new FacebookAuthProvider();

export { auth, db, functions, googleAuthProvider, facebookAuthProvider, projectID };
