import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider, Grid, CircularProgress } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// firebase imports
import { auth, db } from 'firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

// stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { SET_DATA } from 'store/actions';

// ==============================|| APP ||============================== //

const App = () => {
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const storedUser = useSelector((state) => state.user);
    const userData = storedUser.data;

    const [user, setUser] = useState();
    const [userType, setUserType] = useState(userData.type);
    const [loading, setLoading] = useState(true);

    // Stripe Publishable key
    const stripePromise = loadStripe(
        'pk_live_51KK8RaEUQ3RBypuYFBXF0nZCNKgCTq4yHwvnQpty2vJr4ZwW8UMmlnDS2vr8ew8U7igpsZWypRGMh6AD98FdVN1800sTCkxlQZ'
    );

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User

                const userDocRef = doc(db, 'users', user.uid);
                const userDocSnap = await getDoc(userDocRef);

                // check user in firestore
                if (userDocSnap.exists()) {
                    setUser(user);

                    // save user data to store
                    const userData = { id: userDocSnap.id, ...userDocSnap.data() };
                    dispatch({ type: SET_DATA, data: userData });

                    setUserType(userDocSnap.data().type);
                    setLoading(false);
                }
            } else {
                // User is signed out
                setUser();
                setUserType();
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, [dispatch, userType]);

    return (
        <>
            {loading ? (
                <Grid container align="center" justify="center" style={{ paddingTop: 100 }}>
                    <Grid item md={12}>
                        <CircularProgress size={200} />
                    </Grid>
                </Grid>
            ) : (
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={themes(customization)}>
                        <Elements stripe={stripePromise}>
                            <CssBaseline />
                            <NavigationScroll>
                                <LocalizationProvider dateAdapter={DateAdapter}>
                                    <Routes user={user} userType={userType} />
                                </LocalizationProvider>
                            </NavigationScroll>
                        </Elements>
                    </ThemeProvider>
                </StyledEngineProvider>
            )}
        </>
    );
};

export default App;
