import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Invite = Loadable(lazy(() => import('views/invite')));
const Profile = Loadable(lazy(() => import('views/profile')));
const AmbassadorStudents = Loadable(lazy(() => import('views/ambassador-students')));
const AmbassadorEmployers = Loadable(lazy(() => import('views/ambassador-employers')));

// ==============================|| AMBASSADOR ROUTING ||============================== //

const AmbassadorRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Invite />
        },
        {
            path: '/invite',
            element: <Invite />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/students/:profileID/:ambassadorEditing',
            element: <Profile />
        },
        {
            path: '/employers/:profileID/:ambassadorEditing',
            element: <Profile />
        },
        {
            path: '/students',
            element: <AmbassadorStudents />
        },
        {
            path: '/employers',
            element: <AmbassadorEmployers />
        }
    ]
};

export default AmbassadorRoutes;
