// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

// video constants
export const videoModePortrait = 'portrait';
export const videoModeLandscape = 'landscape';
export const videoPlayerWidthPortrait = 'calc(0.56 * (450px + ((100vw - 768px) / 1152) * 100))';
export const videoPlayerHeightPortrait = 'calc(450px + ((100vw - 768px) / 1152) * 100)';
export const videoPlayerWidthLandscape = 'calc(450px + ((100vw - 768px) / 1152) * 100)';
export const videoPlayerHeightLandscape = 'calc((450px + ((100vw - 768px) / 1152) * 100) / 1.78)';
export const videoPreviewDuration = 60;

// connect constants
export const connectStatusPending = 'pending';
export const connectStatusAccepted = 'accepted';
export const connectStatusRejected = 'rejected';

// value of 1 credit in dollars
export const creditValue = 100;

// string limit constants
export const employerHeadlineLimit = 220;
export const employerDescriptionLimit = 300;

// job location types
export const remoteLocation = 'remote';
export const physicalLocation = 'physical';

// job industries
export const jobIndustries = [
    'Marketing',
    'Advertising',
    'Media',
    'Digital marketing',
    'IT services',
    'HR management',
    'Sales',
    'Public relations',
    'Other'
];

export const jobRoleTypes = [
    'Work experience',
    'Paid internship',
    'Unpaid internship',
    'Part-time',
    'Full-time',
    'Casual',
    'Contract',
    'Remote',
    'In-person/office',
    'Other'
];

// pronouns
export const pronouns = ['they/them', 'she/her', 'he/him', 'other'];

// universities
export const universities = [
    'RMIT University',
    'University of Melbourne',
    'Monash University',
    'Deakin University',
    'Victoria University',
    'Swinburne University',
    'Other'
];
export const degrees = ['TAFE', 'Bachelors degree', 'Honours degree', 'Masters degree', 'Other'];
export const studyLevels = ['First year', 'Second year', 'Third year', 'Fourth year', 'Graduated'];
export const studyFields = [
    'Business',
    'Marketing',
    'Commerce',
    'Human Resources',
    'Management',
    'Advertising',
    'Finance',
    'International Business',
    'Other'
];

export const aboutPrompts = [
    {
        label: 'My Myers-Briggs Type Indicator is...',
        id: 'myers-briggs'
    },
    {
        label: 'My greatest strength is...',
        id: 'greatest-strength'
    },
    {
        label: 'That time I overcame a challenge at work/uni...',
        id: 'overcome-challenge'
    },
    {
        label: 'My ideal workplace is...',
        id: 'ideal-workplace'
    },
    {
        label: 'I am looking for...',
        id: 'looking-for'
    },
    {
        label: 'My work philosophy is...',
        id: 'work-philosophy'
    },
    {
        label: 'In my free time...',
        id: 'free-time'
    },
    {
        label: 'When it comes to my work/career, I value...',
        id: 'work-value'
    },
    {
        label: 'My interests are...',
        id: 'interests'
    }
];

export const businessSizes = ['Solopreneur', 'Small (2-19 employees)', 'Medium (20-199 employees)', 'Large (200 or more)'];
