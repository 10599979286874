import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Chip, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography, CircularProgress } from '@mui/material';

// firebase
import { db } from 'firebase';
import { updateDoc, doc, arrayUnion } from 'firebase/firestore';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ loading, notifications, setNotifications, setOpen, userData }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        marginRight: '5px'
    };

    const handleNotificationClick = async (notification, index) => {
        setOpen(false);
        const notificationItem = notifications[index];

        // mark as read
        if (!notificationItem.readBy?.includes(userData.id)) {
            const readBy = notificationItem.readBy || [];
            notificationItem.readBy = [...readBy, userData.id];
        }
        setNotifications(notifications);

        await updateDoc(doc(db, 'notifications', notification.id), { readBy: arrayUnion(userData.id) });

        // redirect
        if (notification.link) {
            navigate(notification.link);
        }
    };

    return (
        <>
            {loading ? (
                <Grid container align="center" justify="center">
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            ) : (
                <List
                    sx={{
                        width: '100%',
                        maxWidth: 330,
                        py: 0,
                        borderRadius: '10px',
                        [theme.breakpoints.down('md')]: {
                            maxWidth: 300
                        },
                        '& .MuiListItemSecondaryAction-root': {
                            top: 22
                        },
                        '& .MuiDivider-root': {
                            my: 0
                        },
                        '& .list-container': {
                            pl: 7
                        }
                    }}
                >
                    {notifications.map((notification, index) => (
                        <Grid key={notification.id}>
                            <ListItemWrapper onClick={() => handleNotificationClick(notification, index)}>
                                <ListItem alignItems="center">
                                    <ListItemAvatar>
                                        <Avatar>{notification.title?.charAt(0)}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={notification.title} />
                                </ListItem>
                                <Grid container direction="column" className="list-container">
                                    <Grid item xs={12} sx={{ pb: 1 }}>
                                        <Typography variant="subtitle2">{notification.text}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ pb: 1 }}>
                                        <Typography variant="caption">{new Date(notification.created).toLocaleString()}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            {notification.readBy?.includes(userData.id) ? (
                                                <Grid item>
                                                    <Chip label="Read" sx={chipSuccessSX} />
                                                </Grid>
                                            ) : (
                                                <Grid item>
                                                    <Chip label="Unread" sx={chipErrorSX} />
                                                </Grid>
                                            )}
                                            {notification.link && (
                                                <Grid item>
                                                    <Chip label={notification.link.replace('/', '')} sx={chipWarningSX} />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ListItemWrapper>
                            <Divider />
                        </Grid>
                    ))}
                </List>
            )}
        </>
    );
};

NotificationList.propTypes = {
    loading: PropTypes.bool,
    notifications: PropTypes.array,
    setNotifications: PropTypes.func,
    setOpen: PropTypes.func,
    userData: PropTypes.object
};

export default NotificationList;
