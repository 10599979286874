// assets
import { IconHome, IconUser, IconBriefcase, IconAffiliate, IconMessage, IconWallet } from '@tabler/icons';

// constant
const icons = {
    IconHome,
    IconUser,
    IconBriefcase,
    IconAffiliate,
    IconMessage,
    IconWallet
};

// ==============================|| EMPLOYER MENU ITEMS ||============================== //

const employerPages = {
    id: 'employerPages',
    title: 'Employer Pages',
    caption: 'Employer Pages Caption',
    type: 'group',
    children: [
        {
            id: 'feed',
            title: 'Feed',
            type: 'item',
            url: '/feed',
            icon: icons.IconHome,
            breadcrumbs: false
        },
        {
            id: 'profile',
            title: 'Profile',
            type: 'item',
            url: '/profile',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'jobListing',
            title: 'Job Listing',
            type: 'item',
            url: '/job-listing',
            icon: icons.IconBriefcase,
            breadcrumbs: false
        },
        {
            id: 'connections',
            title: 'Connections',
            type: 'item',
            url: '/connections',
            icon: icons.IconAffiliate,
            breadcrumbs: false
        },
        {
            id: 'chat',
            title: 'Chat',
            type: 'item',
            url: '/chat',
            icon: icons.IconMessage,
            breadcrumbs: false
        },
        {
            id: 'wallet',
            title: 'Wallet',
            type: 'item',
            url: '/wallet',
            icon: icons.IconWallet,
            breadcrumbs: false
        }
    ]
};

export default employerPages;
